import * as React from "react"
import { createGlobalStyle } from 'styled-components';
import { css } from "glamor";
import { Header, HeaderSpacer } from "../../locust/components/Header";
import X from "../../locust/composables/X";
import SpacerV from "../../locust/sections/SpacerV";
import { FACEBOOK_BLACK, FACEBOOK_BLUE, FACEBOOK_SKYBLUE } from "../../assets/colors/Colors";
import { Link } from "@mui/material";
import { useState } from "react";
import { OrderItemTitle } from "../../client-ui/checkout/Typography";
import { SectionTitle } from "../../client-ui/checkout/CheckoutSectionTitle";
import { BorderedBox } from "../../locust/components/BorderedBox";
import XY from "../../locust/composables/XY";
import { CartItemTitle } from "../../client-ui/checkout/CartItemTitle";
import EditIcon from '@mui/icons-material/Edit';
import { Popup, PopupSlide } from "../../locust/components/PopupSlide";
import { auth, db, isLoggedIn } from "../../../firebase-config";
import { doc, getDoc } from "firebase/firestore";
import { CircularProgress } from '@mui/material'
import { getRandomString } from "../../locust/composables/Shared";
import CobraLayout from "../../client-ui/shared/LayoutCobra";
import AuthManager from "../../locust/composables/AuthManager";
import { Page, to } from "../../client-ui/shared/SiteLinks";
import { LoaderFullScreen, LoaderFullScreenSwitch } from "../../client-ui/shared/LoaderFullScreen";
import { AirlineSeatLegroomExtra } from "styled-icons/material-outlined";
import { parseSubscriptions } from "./view-subscription";
import { ENABLE_ERROR_LOGGING } from "../../settings/project-settings";
//import { parseSubscriptions } from "./view-subscription";



export const GlobalStyle = createGlobalStyle`
*{
    margin:0;
    padding:0;
}
html, body {
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
}
body {
    position: relative;
    width: 100%;
    background-image: radial-gradient(farthest-corner at max(50%,150px) 50%,
        rgba(249, 249, 249, 0.5) 0%, rgba(249, 249, 249, 0.5) 20%, rgba(249, 249, 249, 0.5) 70%,#dae5ea 100%);
        height : 2000px
}
img{
    max-width:100%;
}  
`;


const breadcrumb = [
    { value : 'Home', url : '/index' },
    { value : 'Profile', url : `/profile` },
 ]

export default () => {
    const [loggedIn, setLoggedIn ] = React.useState(false)
    return (
        <>
            <AuthManager setLoggedIn={setLoggedIn}/>
            <CobraLayout headerType="store" loggedIn={loggedIn} breadcrumb={breadcrumb}>
                <Profile loggedIn={loggedIn}/>
            </CobraLayout>
        </>
    )
}


const Profile = ( props : { loggedIn : boolean }) => {
    
    const [ orderData, setOrderData] = useState<any>(undefined)
    const [ loaded, setLoaded ] = useState<any>(false)
    const [ email, setEmail ] = useState("")
    const [ displayName, setDisplayName] = useState("")
    const [ company, setCompany ] = useState("")
    const [ phone, setPhone] = useState("")
    
    const [ firstNameShipping, setShippingFirstName ] = useState("First name")
    const [ lastNameShipping, setShippingLastName ] = useState("Last name")
    const [ countryShipping, setShippingCountry ] = useState("US")
    const [ stateShipping, setShippingState ] = useState("State")
    const [ addressShipping, setShippingAddress ] = useState("Address")
    const [ addressTwoShipping, setShippingAddressTwo ] = useState("Address 2") // "Address 2 (building, code...) - Optional"
    const [ zipcodeShipping, setShippingZipcode ] = useState("Zipcode")
    const [ cityShipping, setShippingCity ] = useState("City")

    const [ firstNameBilling, setBillingFirstName ] = useState("First")
    const [ lastNameBilling, setBillingLastName ] = useState("Last")
    const [ countryBilling, setBillingCountry ] = useState("US")
    const [ stateBilling, setBillingState ] = useState("State")
    const [ addressBilling, setBillingAddress ] = useState("Address")
    const [ addressTwoBilling, setBillingAddressTwo ] = useState("Address 2") // "Address 2 (building, code...) - Optional"
    const [ zipcodeBilling, setBillingZipcode ] = useState("Zipcode")
    const [ cityBilling, setBillingCity ] = useState("City")
    
    const userData = {
        email : email, 
        setEmail : setEmail,
        //password : password, 
        //setPassword : setPassword, 
        //passwordVerify : passwordVerify, 
        //setPasswordVerify : setPasswordVerify, 
        firstNameShipping : firstNameShipping, 
        setShippingFirstName : setShippingFirstName, 
        lastNameShipping : lastNameShipping, 
        setShippingLastName : setShippingLastName, 
        countryShipping : countryShipping, 
        setShippingCountry : setShippingCountry, 
        stateShipping : stateShipping, 
        setShippingState : setShippingState, 
        addressShipping : addressShipping, 
        setShippingAddress : setShippingAddress, 
        addressTwoShipping : addressTwoShipping, 
        setShippingAddressTwo : setShippingAddressTwo, 
        zipcodeShipping : zipcodeShipping, 
        setShippingZipcode : setShippingZipcode, 
        cityShipping : cityShipping, 
        setShippingCity : setShippingCity, 
        firstNameBilling : firstNameBilling, 
        setBillingFirstName : setBillingFirstName, 
        lastNameBilling : lastNameBilling, 
        setBillingLastName : setBillingLastName, 
        countryBilling : countryBilling, 
        setBillingCountry : setBillingCountry, 
        stateBilling : stateBilling, 
        setBillingState : setBillingState, 
        addressBilling : addressBilling, 
        setBillingAddress : setBillingAddress, 
        addressTwoBilling : addressTwoBilling, 
        setBillingAddressTwo : setBillingAddressTwo, 
        zipcodeBilling : zipcodeBilling, 
        setBillingZipcode : setBillingZipcode, 
        cityBilling : cityBilling, 
        setBillingCity : setBillingCity, 
        company : company, 
        setCompany : setCompany, 
        phone : phone, 
        setPhone : setPhone, 
    }
    

    const [ popup, setPopup ] = useState<Popup>({ toggle : false, title : "" })
    const style = {
        height : 'auto', 
        paddingBottom : 25, 
        maxWidth : 400,
        marginLeft : 'max(25px, calc(50% - 200px))',
        width : 'calc(100% - 52px)',
    }
    

    React.useEffect(()=>{

        auth.onAuthStateChanged( async ()=>{
            if( ENABLE_ERROR_LOGGING ) console.log(auth.currentUser)
            setEmail(String(auth && auth.currentUser ? auth.currentUser.email : ''))
            
            const refI = doc(db, `cardData/${auth.currentUser?.uid}`)
            const cardData = await getDoc(refI).then(( rsp : any )=>rsp.data())
            const currenCardId = cardData.currentCard
            const card = cardData[currenCardId]
            setDisplayName( card.cardName )
            if( ENABLE_ERROR_LOGGING ) console.log(card)

            const ordersRef = doc(db, `orders/${auth.currentUser?.uid}`)
            const orderData : any = await getDoc(ordersRef).then(x=>x.data())
            let ordersFiltered = {}
            Object.keys(orderData).filter(x => x !== "paid" && x !== "customer").forEach( ( x ) => {
                ordersFiltered = {
                    ...ordersFiltered, 
                    [x] : orderData[x]
                }
            })
            
            //if( ENABLE_ERROR_LOGGING ) console.log(ordersFiltered)

            setOrderData( orderData )
            setLoaded( true )

            if( ENABLE_ERROR_LOGGING ) console.log(orderData.customer)

            


            setShippingFirstName(orderData.customer.firstNameShipping )
            setShippingLastName(orderData.customer.lastNameShipping )
            setShippingCountry(orderData.customer.countryShipping )
            setShippingState(orderData.customer.stateShipping )
            setShippingAddress(orderData.customer.addressShipping )
            setShippingAddressTwo(orderData.customer.addressTwoShipping )
            setShippingZipcode(orderData.customer.zipcodeShipping )
            setShippingCity(orderData.customer.cityShipping )
            setBillingFirstName(orderData.customer.firstNameBilling ? orderData.customer.firstNameBilling : orderData.customer.firstNameShipping )
            setBillingLastName(orderData.customer.lastNameBilling ? orderData.customer.lastNameBilling : orderData.customer.lastNameShipping  )
            setBillingCountry(orderData.customer.countryBilling ? orderData.customer.countryBilling : orderData.customer.countryShipping  )
            setBillingState(orderData.customer.stateBilling ? orderData.customer.stateBilling  : orderData.customer.stateShipping  )
            setBillingAddress(orderData.customer.addressBilling ? orderData.customer.addressBilling  : orderData.customer.addressShipping  )
            setBillingAddressTwo(orderData.customer.addressTwoBilling ? orderData.customer.addressTwoBilling  : orderData.customer.addressTwoShipping  )
            setBillingZipcode(orderData.customer.zipcodeBilling ? orderData.customer.zipcodeBilling : orderData.customer.zipcodeShipping  )
            setBillingCity(orderData.customer.cityBilling ? orderData.customer.cityBilling : orderData.customer.cityShipping )


            // const orderRef = doc(db, `orders/${auth.currentUser?.uid}`)
            // const orderData : any = await getDoc(orderRef).then(x=>x.data())
            
            

        })
        
    },[])

    
    return (
        <>

        

            
               
                <SpacerV h={50}/>
                <X>
                    <SectionTitle style={{ width : '100%', marginTop : -20}}>
                        <p style={{ fontSize : 36, fontWeight : 800 }}>User Profile</p>
                    </SectionTitle> 
                </X>
                
                <LoaderFullScreenSwitch switch={ !loaded } y={-400}>
                    <UserInfo {...userData} style={style} setPopup={setPopup} name={displayName} email={email}/>
                    <ShippingInfo {...userData} style={style} setPopup={setPopup}/>
                    <BillingInfo {...userData} style={style} setPopup={setPopup}/>
                    <SubscriptionInfo orders={orderData} style={style} setPopup={setPopup}/>
                </LoaderFullScreenSwitch>
                
            

            
                
                
            
            
            <PopupSlide popup={popup} setPopup={setPopup} title={"Title"}/>
        </>
    )
}



export const UserInfo = ( props : { 
    setPopup : ( props : Popup ) => void, 
    style ?: Record<string,any>
    name ?: string, 
    email ?: string, 

} ) => {

    const height : number = 150
    const iconStyle : Record<string,any> = {
        cursor : 'pointer', 
        color : FACEBOOK_BLACK,
        ':hover' : { color : '#1877F2'}, 
        ':active' : { color : FACEBOOK_SKYBLUE}
    }

    return (
        <InfoBox style={{ 

           ...props.style
            //[onBreakTwo] : {
              //  //backgroundColor : 'purple',
                //width : 300 - 50
            //}
        }}>
                <X>
                <div style={{ width : `calc(100% - ${60}px)`, backgroundColor : '', height : 'auto'}}>
                    <SectionTitle style={{ marginTop : 0, height : 'auto', fontSize : 16  }}>
                        My Info
                    </SectionTitle>
                    <div style={{ marginLeft : 25, marginTop : -16  }}>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.name}
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.email}
                        </OrderItemTitle>
                        <SpacerV h={4}/>
                        <Link href={`${to(Page.PROFILE)}`} {...css({ ':link' : { textDecoration : 'none'}})}>
                            <CartItemTitle style={iconStyle}>Reset Password</CartItemTitle>
                        </Link>
                    </div>
                </div>
                <div 
                    {...css({...iconStyle, width : 60 })}
                    onClick={()=>{ 
                        props.setPopup({ 
                            toggle : true, 
                            title : 'My Info',
                            fields : {
                                "First Name" : {},
                                "Last Name" : {},
                                "Email" : {}   
                            } 
                        })   
                    }}>
                    <XY>
                        <EditIcon/>
                    </XY>
                </div>
                </X>
            
        </InfoBox>
    )
}




export const SubscriptionInfo = ( props : { 
    setPopup : ( props : Popup ) => void, 
    style ?: Record<string,any>
    orders : any

} ) => {

    

    
    const [ subscriptionData, setSubscriptionData ] = React.useState<any>(false)
    
    React.useEffect(()=>{
        const parsedValidSubscriptions : any = parseSubscriptions( props.orders )
        if( ENABLE_ERROR_LOGGING ) console.log( parsedValidSubscriptions )
        if( props.orders ) setSubscriptionData(parsedValidSubscriptions)
    },[])

    
    const height : number = 150
    const iconStyle : Record<string,any> = {
        cursor : 'pointer', 
        color : FACEBOOK_BLACK,
        ':hover' : { color : '#1877F2'}, 
        ':active' : { color : FACEBOOK_SKYBLUE}
    }

    

    // const NO_SUBSCRIPTION_DATA = subscriptionData === undefined 


    return (
        <InfoBox style={{ height, ...props.style }}>
            <X>
                <div style={{ width : `calc(100% - ${60}px)`, backgroundColor : '', height : 'auto'}}>
                    <SectionTitle style={{ marginTop : 0, height : 'auto', fontSize : 16  }}>
                        Active Subscriptions
                    </SectionTitle>


                    <div style={{ marginLeft : 25, marginTop : -16  }}>

                        { !subscriptionData  && 
                            <XY>
                                <CircularProgress style={{ marginLeft : 50, color : FACEBOOK_BLUE }} size={20}/> 
                            </XY>
                        }
    
                        {
                            subscriptionData && <>
                                
                                { Object.keys(subscriptionData).sort().map(( keyValue : string )=>{

                                   

                                    return (
                                        <div key={getRandomString()}>
                                            { subscriptionData[keyValue].active && 
                                            <>
                                                <div style={{ display : 'inline-block' }}>  
                                                    <OrderItemTitle style={{ marginTop : 6 }}>
                                                        {keyValue}
                                                    </OrderItemTitle>
                                                </div>
                                                {/*
                                                    [TO-DO][COSTS] : OPTIMIZE: this should be dont via a url-rewriting to transfer data to the 
                                                        subscription page, rather than making another call (which actually would still result in 
                                                        additional calls as soon as they hit the back button.)
                                                        The ultamately solution is to store the data in the header, and then keep the same header
                                                        on every single page. 

                                                         // const serializedDataUrl = keyValue + "?" + new URLSearchParams(subscriptionData[keyValue]).toString();
                                                        // if( ENABLE_ERROR_LOGGING ) console.log(serializedDataUrl)
                                                        var serializedDataUrl = encodeURIComponent(
                                                            Object.keys(subscriptionData).map( key => key + '=' + subscriptionData[key]).join('&')
                                                        )
                                                */}
                                                <div style={{ display : 'inline-block', marginLeft : 10 }}>
                                                    <Link href={`${to(Page.PROFILE)}/${keyValue}`} {...css({ marginTop : 2, fontSize : 14, ':link' : { textDecoration : 'none'}})}>
                                                        <CartItemTitle style={iconStyle}>View</CartItemTitle>
                                                    </Link>
                                                </div>
                                                </>
                                            }
                                        </div>
                                    )
                                })}
                                

                                
                            
                            
                            </>
                        }

                        {/* {
                            (!subscriptionData && NO_SUBSCRIPTION_DATA ) && 
                                <>
                                
                                    <OrderItemTitle style={{ marginTop : 6 }}>
                                        None
                                    </OrderItemTitle>

                                
                            
                            
                                </>
                        } */}


                        
                    </div>
                </div>
            </X>
        </InfoBox>
    )
}

export const BillingInfo = ( props : { 
    setPopup : ( props : Popup ) => void, 
    style ?: Record<string,any>
    firstNameBilling : string, 
    setBillingFirstName : ( value : string ) => void,
    lastNameBilling : string, 
    setBillingLastName : ( value : string ) => void,
    countryBilling : string, 
    setBillingCountry : ( value : string ) => void,
    addressBilling : string, 
    setBillingAddress : ( value : string ) => void,
    addressTwoBilling : string, 
    setBillingAddressTwo : ( value : string ) => void,
    cityBilling : string, 
    setBillingCity : ( value : string ) => void,
    stateBilling : string, 
    setBillingState : ( value : string ) => void,
    zipcodeBilling : string, 
    setBillingZipcode : ( value : string ) => void,
    phone : string, 
    setPhone : ( value : string ) => void,
} ) => {

    const height : number = 190
    
    const iconStyle : Record<string,any> = {
        cursor : 'pointer', 
        color : FACEBOOK_BLACK,
        ':hover' : { color : '#1877F2'}, 
        ':active' : { color : FACEBOOK_SKYBLUE}
    }

    return (
        <InfoBox style={{ height : height, ...props.style }}>
            <X>
                <div style={{ width : `calc(100% - ${60}px)`, backgroundColor : '', height : 'auto'}}>
                    <SectionTitle style={{ marginTop : 0, height : 'auto', fontSize : 16  }}>
                        Billing Info
                    </SectionTitle>
                    <div style={{ marginLeft : 25, marginTop : -16  }}>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {`${props.firstNameBilling} ${props.lastNameBilling}`}
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.addressBilling}
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.cityBilling}
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.stateBilling}
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.zipcodeBilling}
                        </OrderItemTitle>
                        
                        
                    </div>
                </div>
                <div 
                    {...css({...iconStyle, width : 60 })}
                    onClick={()=>{ 
                        props.setPopup({ 
                            toggle : true, 
                            title : 'Billing Info',
                            fields : {
                                "First Name" : {},
                                "Last Name" : {},
                                "Street Address" : {},   
                                "Address 2 (building, code...) - Optional" : {},
                                "City" : {},
                                "State" : {},
                                "Zip" : {},
                                "Country" : {},
                            } 
                        })   
                    }}>
                    <XY>
                        <EditIcon/>
                    </XY>
                </div>
            </X>
            
        </InfoBox>
    )
}


export const ShippingInfo = ( props : { 
    setPopup : ( props : Popup ) => void, 
    style ?: Record<string,any>
    email : string,
    
    firstNameShipping : string, 
    setShippingFirstName : ( value : string ) => void,
    lastNameShipping : string, 
    setShippingLastName : ( value : string ) => void,
    countryShipping : string, 
    setShippingCountry : ( value : string ) => void,
    addressShipping : string, 
    setShippingAddress : ( value : string ) => void,
    addressTwoShipping : string, 
    setShippingAddressTwo : ( value : string ) => void,
    cityShipping : string, 
    setShippingCity : ( value : string ) => void,
    stateShipping : string, 
    setShippingState : ( value : string ) => void,
    zipcodeShipping : string, 
    setShippingZipcode : ( value : string ) => void,

    phone : string, 
    setPhone : ( value : string ) => void,
} ) => {

    const height : number = 190
    
    const iconStyle : Record<string,any> = {
        cursor : 'pointer', 
        color : FACEBOOK_BLACK,
        ':hover' : { color : '#1877F2'}, 
        ':active' : { color : FACEBOOK_SKYBLUE}
    }

    return (
        <InfoBox style={{ height : height, ...props.style }}>
            <X>
                <div style={{ width : `calc(100% - ${60}px)`, backgroundColor : '', height : 'auto'}}>
                    <SectionTitle style={{ marginTop : 0, height : 'auto', fontSize : 16  }}>
                        Shipping Info
                    </SectionTitle>
                    <div style={{ marginLeft : 25, marginTop : -16  }}>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {`${props.firstNameShipping} ${props.lastNameShipping}`}
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.addressShipping}
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.cityShipping}
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.stateShipping}
                        </OrderItemTitle>
                        <OrderItemTitle style={{ marginTop : 6 }}>
                            {props.zipcodeShipping}
                        </OrderItemTitle>
                        
                        
                    </div>
                </div>
                <div 
                    {...css({...iconStyle, width : 60 })}
                    onClick={()=>{ 
                        props.setPopup({ 
                            toggle : true, 
                            title : 'Shipping Info',
                            fields : {
                                "First Name" : {},
                                "Last Name" : {},
                                "Street Address" : {},   
                                "Address 2 (building, code...) - Optional" : {},
                                "City" : {},
                                "State" : {},
                                "Zip" : {},
                                "Country" : {},
                            } 
                        })   
                    }}>
                    <XY>
                        <EditIcon/>
                    </XY>
                </div>
            </X>
        </InfoBox>
    )
}
const InfoBox = ( props : { children ?: any, style ?: Record<string,any> }) => {
    return (
        <BorderedBox 
                style={{ 
                    maxWidth : 400,
                    width : '100%',
                    //padding : 20,
                    borderRadius : 4,
                    margin: 20,
                    marginLeft : 0,
                    ...props.style
                }
            }>    
            { props.children }
        </BorderedBox>
    )
}

export const getSubscriptionsFromRemote = ( props : { 
    onSuccess ?: ( response : any ) => void 
}) => {
    const uid : string = auth.currentUser?.uid as string
    getDoc(doc(db, `orders/${uid}`)).then(( response )=>{
        if( props.onSuccess )
            props.onSuccess( response.data() )
    })
}



